import React, { useState, useEffect } from 'react';
import '../../styles/Navbar.css';

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY) {
        // User is scrolling down, hide the navbar
        setShowNavbar(false);
      } else {
        // User is scrolling up, show the navbar
        setShowNavbar(true);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  return (
    <div className={`navbar ${showNavbar ? 'navbar-visible' : 'navbar-hidden'}`}>
      <img className="navbar-logo" src="/assets/images/logo/civiLogo.png" alt="Civi Logo" />
      {/* Add other navbar items here */}
    </div>
  );
};

export default Navbar;
