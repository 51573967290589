import React from 'react';
import HomeScreen from './pages/Homescreen'; // Adjust the path if necessary


function App() {
  return (
    <div>
      <HomeScreen />
    </div>
  );
}

export default App;
