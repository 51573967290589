import React, { useRef, useEffect } from 'react';
import '../../styles/InputContainer.css'; // Create a separate CSS file for styling

const InputContainer = ({ url, setUrl, handleSubmit }) => {
  const textareaRef = useRef(null);

  // Handle input changes
  const handleInputChange = (e) => {
    setUrl(e.target.value);
  };

  // Adjust textarea height based on content
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'; // Reset the height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set the height based on scroll height
    }
  }, [url]); // Run this effect whenever the `url` state changes

  return (
    <div className="input-container-fixed">
      <textarea
        ref={textareaRef}
        className="input"
        placeholder="Paste a TikTok, Youtube or News link or just search a cause" 
        value={url}
        onChange={handleInputChange}
        style={{ height: 'auto' }} // Optional inline style to ensure auto height
      ></textarea>
      <div className="input-divider">
        <button className="send-button" onClick={handleSubmit}>
          <img
            src="/assets/icons/send_icon.png"
            className="send-icon"
            alt="Send Icon"
          />
        </button>
      </div>
    </div>
  );
};

export default InputContainer;
